// Import dependencies
import React from "react"

// Import components
import Layout from "../layouts/layout"
import SEO from "../components/seo/seo"
import Logo from "../assets/icons/logo_green_login.svg";
import { Link, navigate } from "gatsby"
import LoginComponent from "../components/account/login"

// Import styles
import "./account.css"

// Import from Redux
import { connect } from "react-redux"
import { logUserIn } from "../redux/actions/userAction";

// Ger firebase API
import { getFirebase } from "../api/firebase";
/*
    Account page
*/
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.isBrowser = this.isBrowser.bind(this);
    this.registerWithGoogle = this.registerWithGoogle.bind(this);
    this.registerWithFacebook = this.registerWithFacebook.bind(this);
  }
  isBrowser = () => typeof window !== "undefined";

  registerWithGoogle = () => {
    this.setState({ error: null, isLoading: true });
  
    const app = import("firebase/app");
    const auth = import("firebase/auth");
    const firestore = import("firebase/firestore");
  
    Promise.all([app, auth, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(async (result) => {
          const user = result.user;
  
          // Fetch additional user data from Firestore
          getFirebase(firebase)
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const userData = doc.data();
                const { coin } = userData;
                const { phone, first_name, last_name, profil_img } = userData.account_info;
  
                const userDetails = {
                  uid: user.uid,
                  email: user.email,
                  profil_img,
                  phone,
                  first_name,
                  last_name,
                  coin,
                };
  
                // Set the user in state and update 'user' in localStorage
                this.props.dispatch(logUserIn(userDetails));
                localStorage.setItem("user", JSON.stringify(userDetails));
  
                this.setState({ isLoading: false });
                const redirectUrl = window.location.search.includes("redirectShop=true") ? "/shop" : "/account";
                navigate(redirectUrl);
              } else {
                console.log("No such document!");
                this.setState({ isLoading: false });
              }
            })
            .catch((error) => {
              console.error("Error getting document:", error);
              this.setState({ isLoading: false, error: "Failed to fetch user data" });
            });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error: error.message, isLoading: false });
        });
    });
  };
  
  registerWithFacebook = () => {
    this.setState({ error: null, isLoading: true });
  
    const app = import("firebase/app");
    const auth = import("firebase/auth");
    const firestore = import("firebase/firestore");
  
    Promise.all([app, auth, firestore]).then(([firebase]) => {
      getFirebase(firebase)
        .auth()
        .signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(async (result) => {
          const user = result.user;
  
          // Fetch additional user data from Firestore
          getFirebase(firebase)
            .firestore()
            .collection("users")
            .doc(user.uid)
            .get()
            .then((doc) => {
              if (doc.exists) {
                const userData = doc.data();
                const { coin } = userData;
                const { phone, first_name, last_name, profil_img } = userData.account_info;
  
                const userDetails = {
                  uid: user.uid,
                  email: user.email,
                  profil_img,
                  phone,
                  first_name,
                  last_name,
                  coin,
                };
  
                // Set the user in state and update 'user' in localStorage
                this.props.dispatch(logUserIn(userDetails));
                localStorage.setItem("user", JSON.stringify(userDetails));
  
                this.setState({ isLoading: false });
                const redirectUrl = window.location.search.includes("redirectShop=true") ? "/shop" : "/account";
                navigate(redirectUrl);
              } else {
                console.log("No such document!");
                this.setState({ isLoading: false });
              }
            })
            .catch((error) => {
              console.error("Error getting document:", error);
              this.setState({ isLoading: false, error: "Failed to fetch user data" });
            });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ error: error.message, isLoading: false });
        });
    });
  };
  

  componentWillMount() {
    if (this.props.loggedIn && this.isBrowser()) {
      const redirectUrl = window.location.search.includes("redirectShop=true") ? "/shop" : "/account";
      navigate(redirectUrl);
    }
  }

  render() {
    // Get data
    const { location } = this.props;

    return (
      <Layout noWhatsapp noHeader loginFooter>
      <SEO title="Account" description="Login to your account." />
      <div className="account_container">
        <Link to="/" className="account_logo" >
          <Logo />
        </Link>
        <LoginComponent
      redirect={location.state && location.state.redirect}
      stepRedirect={location.state && location.state.fromStep ? true : false}
      recycleRedirect={location.state && location.state.fromRecycle ? true : false}
      logItem={location.state && location.state.fromRecycle ? location.state.logItem : ''}
      registerWithGoogle={this.registerWithGoogle}
      registerWithFacebook={this.registerWithFacebook}
        />
      </div>
    </Layout>
    )
  }
}

// Connect redux to component
const mapStateToProps = state => ({
  loggedIn: state.user.isLoggedIn
})
export default connect(mapStateToProps)(Login)